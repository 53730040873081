.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .fixedSection {
    color: #a2a6ac;
    display: grid;
    min-height: 100vh;
    margin: 0;
    background-color: #212930;
    grid-template-rows: auto 1fr auto;
  }

  .navbar-dark .navbar-nav .nav-link {
    color: #f7f7f7;
  } 
  
  .jumbotron {
    background-color: #2d3843;
  }
  
  .tdSeller {
    background-color: #f7f7f7; 
    color: #0c8150;
  }
  
  .tdBuyer {
    background-color: #f7f7f7; 
    color: #53020a;
  }
  
  .jumbotron h1, h2, h3, h4, h5{
    color: #a2a6ac;
  }

  .backGrey {
    background-color: #f7f7f7;
  }
  
  label {
    display: block;
    margin-top: 10px;
  }

  .noVisible {
    display: none;
  }

  .tabs {
    display: flex;
  }
  
  .tab {
      border: 2px solid #2d3843;
      border-radius: .3rem;
      cursor: pointer;
      color: #2d3843;
      padding: 10px;
      margin-right: 10px;
      border-bottom: none;
      background-color: #a2a6ac;
  }
  
  .tab.active {
      background-color: #f7f7f7;
  }
  
  .tab-content {
      border: 2px solid #2d3843;
      border-radius: .3rem;
      padding: 10px;
  }

  .chart-container {
    margin-bottom: 5rem;
    width: 100%;
    color: #a2a6ac;
  }

  .inputSelect {
    margin-top: 0.3rem;
    vertical-align: text-bottom;
  }
  
  .input-search {
    width: fit-content;
    border-radius: 0.3rem;
    border: 1px solid #2d3843;
    padding: 4px;
    height: 100%;
    background-color: #a2a6ac;
    color: #2d3843;
  }
  
  .label-search {
    display: inline-block;
    margin-right: 10px;
  }

  .label-input {
    display: inline-block;
    margin-left: 10px;
  }

  .boxCheck {
    border: 2px solid #2d3843;
    border-radius: .3rem;
    padding: 0;
    margin: 10px 0;
  }
  
  .react-date-picker__wrapper {
    border: 0px !important;
  }
  
  table {
    width: 100%;
    padding: 1em;
    margin: 20px 0;
    border-collapse: collapse;
    box-shadow: 0 1px 2px 0 #a2a6ac;
  }
  
  thead {
    background-color: #a2a6ac;
    color: #f7f7f7;
    font-size: 16px;
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
  }
  
  th {
    border: 1px solid #dee2e6;
  }
  
  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    text-align: center;
  }
  
  tr:nth-child(even) {
    background-color: #e0e0e0;
  }
  
  td {
    padding: 0.75rem;
    vertical-align: top;
    border: 1px solid #dee2e6;
  }
  
  .rc-pagination-item-active {
    background-color: #215968 !important;
    border-color: #215968 !important;
    color: #f7f7f7 !important;
  }
  
  .rc-pagination-item:hover {
    border: 1px solid #215968 !important;
    color: #215968 !important;
  }
  
  .card-container.card {
    max-width: 350px !important;
    padding: 40px 40px;
  }
  
  .card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  }
  
  .profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  
  .bg-antracita {
    background-color: #939CA1 !important
  }
  
  .divFooter {
    padding: 1rem 1rem!important;
    background: #939CA1;
    
    width: 100%;
    color:#f7f7f7;
  }
  td a {
    color: inherit;
  }
  
  .minHeight748 {
    min-height: 775px;
  }
  
  .minHeight714 {
    min-height: 742px;
    text-align: center;
  }
  
  @media (min-width: 768px) { 
    .ueBandera {
      width: 50% !important;
    }
  }
  
  .margin-bottom1 {
    margin-bottom: 1rem;
  }
  
  .marginLeft68 {
    margin-left: 68%;
  }
  
  .margin01 {
    margin: 0 0.1rem;
  }
  
  .btn-primary {
    color: #a2a6ac;
    background-color: #2d3843;
    border-color: #2d3843;
  }
  
  .btn-primary:hover {
    color: #a2a6ac;
    background-color: #2d3843;
    border-color: #2d3843;
  }
  
  .btn-primary:focus {
    color: #a2a6ac;
    background-color: #2d3843;
    border-color: #2d3843;
  }
  
  .btn-primary:active {
    color: #a2a6ac;
    background-color: #2d3843;
    border-color: #2d3843;
  }
  
  .smtpText {
    color: #939CA1;
    font-size: 20px;
    font-style: italic;
    text-align: center;
    background-color: #fff;
    border-radius: 0.3rem;
    padding: 2.5rem 1rem;
  }
  
  .smtpText p::before{
    display: block;
    padding-left: 10px;
    content: "\201C";
    font-size: 80px;
    position: absolute;
    left: -14px;
    top: -17px;
    color: #939CA1;
  }
  
  .smtDiv {
    text-align: center;
    background-color: #fff;
    margin-bottom: 2rem;
    border-radius: 0.3rem;
  }
  
  .padding10 {
    padding: 10px 0;
  }
  
  .marginTop2 {
    margin-top: 2%;
  }

  .marginTop2rem {
    margin-top: 2rem;
  }
  
  .width100 {
    width: 100%;
  }
  
  .width75 {
    width: 75%;
  }

  .width69 {
    width: 69%;
  }
  
  .width50 {
    width: 50%;
  }
  
  .width25 {
    width: 25%;
  }
  
  .height100 {
    height: 100%;
  }
  
  .borderRadius05 {
    border-radius: 0.5rem;
  }
  
  .alignCenter {
    text-align: center;
  }
  
  .alignRight {
    text-align: right;
  }

  .alignLeft {
    text-align: left;
  }
  
  .weightBold {
    font-weight: bold;
  }